<main fxLayout="column" fxFlex="100" >
<section fxLayout="column" fxLayoutAlign="center left" class="modal-title">
    <p>{{data.title}}</p>
</section>
<section class="message">
    <p>{{data.message}}</p>
</section>
    <section *ngIf="data.toDelete" class="message-warn">
        <div class="alert">
            <span><mat-icon class="alert-icon">warning</mat-icon></span><p>{{data.toDelete? data.toDelete : ""}}</p>
        </div>
    </section>
    <section *ngIf="data.checkbox">
        <section class="need-more-analysis">
            <mat-checkbox [(ngModel)]="isMoreAnalysisRequired" class="example-margin">Need more analysis</mat-checkbox>
        </section>
    </section>
<section fxLayout="row" fxLayoutAlign="end"  fxLayoutGap="8px" class="confirm-section">
    <button mat-raised-button [mat-dialog-close]="{action:responses.cancel}">Cancel</button>
    <button mat-raised-button class="confirm"  [mat-dialog-close]="{
    action:responses.ok,
    requiredAnalysis: isMoreAnalysisRequired
    }
    " cdkFocusInitial>Confirm</button>
</section>
</main>
