import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IModal, IModalResponse, RESPONSE } from "../types";

@Component({
  selector: "app-confirm-modal",
  templateUrl: "./confirm-modal.component.html",
  styleUrls: ["./confirm-modal.component.scss"],
})
export class ConfirmModalComponent implements OnInit {
  responses: any;
  isMoreAnalysisRequired: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IModal
  ) {
    this.isMoreAnalysisRequired = false;
    this.responses = { ok: RESPONSE.OK, cancel: RESPONSE.CANCEL };
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {}
}
