export interface Purl {
  id: number;
  // Id_group: number,
  name: string;
  purl_status: number;
  purl_type: number;
  repo_url: string;
  search_url: string;
  id_group: number;
  cpe_status: CPE_STATE;
}

export interface MineType {
  id: number;
  mine_name: string;
  purl_type: string;
}

export interface Group {
  id: number;
  description: string;
  purls: Array<PurlInGroup>;
}

export interface PurlInGroup {
  id: number;
  name: string;
  purl_type: number;
  purl_status: number;
  repo_url: string;
  search_url: string;
}

export interface IUserLogin {
  usr: string;
  psw: string;
}

export interface ILink {
  id: number;
  purl_id: number;
}

export interface Iperformance {
  initDate: string;
  dueDate: string;
  id: number;
}

export interface ISession {
  id: number;
  token: string;
  name: string;
  level: number;
}

export interface IUserPerformance {
  groups_created: number;
  groups_reviewed: number;
  logins: number;
  logout: number;
  purls_linked: number;
  purls_unlinked: number;
  session_expires: number;
  linked_cpes: number;
  reviewed_cpes: number;
  updated_cpes:number;
  working_hours: string;
}

export interface IReview {
  id: number;
  status: number;
  purls: Array<Purl>;
  modified: Date;
  purlsInGroup: number;
}

export enum RESPONSE {
  OK,
  CANCEL,
}

export interface IModalResponse {
  action: RESPONSE;
  requiredAnalysis?: boolean;
}

export interface IModal {
  title?: string;
  message: string;
  toDelete: string;
  checkbox?: boolean;
}

export interface ISessionModal {
  title?: string;
  message: string;
  header: string;
}

export enum CPE_STATUS {
  ASSIGN = 7,
  NOT_ASSIGN = 0,
}

export interface ICPE {
  id: number;
  cpe: string;
  id_state: CPE_STATUS;
  id_curator?: number;
  id_status?: number;
  creation_date?: Date;
  curated_date?: Date;
  google_url: string;
  severity_url: string;
}

export interface ICPE_LINK_UNLINK {
  id_cpe: number;
  id_confirm: Array<number>;
  id_new: Array<number>;
  id_delete: Array<number>;
  modified: boolean;
  review_mode: boolean;
}

export interface CPE_LINKING {
  purls: Array<Purl>;
  modified: boolean;
}

export enum CPE_STATE {
  CONFIRM = 4,
  LINKED = 2,
  UNLINKED = 10,

}

export enum CPE_ORIGIN {
  ORIGINAL,
  NOT_ORIGINAL,
}
