import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { RouterTabs } from "./router-tab/router-tabs.directive";
import { RouterTab } from "./router-tab/router-tab.directive";
import { ConfirmModalComponent } from "./confirm-modal/confirm-modal.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FormsModule } from "@angular/forms";
import { AlertModalComponent } from "./alert-modal/alert-modal.component";
import { MatIconModule } from "@angular/material/icon";
import { SnackbarComponent } from "./snackbar/snackbar.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule,
    MatIconModule,
  ],
  declarations: [
    PageNotFoundComponent,
    RouterTabs,
    RouterTab,
    ConfirmModalComponent,
    AlertModalComponent,
    SnackbarComponent,
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    PageNotFoundComponent,
    RouterTabs,
    RouterTab,
  ],
})
export class SharedModule {}
