import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Iperformance, ISession, Purl } from "./types";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";

const BASE_URL = environment.BASE_URL;

@Injectable({
  providedIn: "root",
})
export class PerformanceServiceService {
  constructor(private http: HttpClient) {}
  public getPerformance(data: Iperformance): Observable<any> {
    return this.http
      .get(
        `${BASE_URL}/curation_api/performance?id=${data.id}&start_date=${data.initDate}&end_date=${data.dueDate}`
      )
      .pipe(
        map((p: any) => {
          p.working_hours = this.toHoursAndMinutes(p.working_hours);
          return p;
        })
      );
  }

  public getUsers(): Observable<any> {
    return this.http.get(`${BASE_URL}/curation_api/users`).pipe(
      map((users: any[]) => {
        const orderedUsers = users.sort((a, b) => a.usr.localeCompare(b.usr));
        return orderedUsers;
      })
    );
  }

  private toHoursAndMinutes(totalMinutes: number) {
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);

    return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}`;
  }

  private padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
}
