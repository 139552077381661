import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { ISession } from "./shared/types";
import { UserServiceService } from "./shared/user-service.service";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private userService: UserServiceService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const sessionData = sessionStorage.getItem("session");
    let token = null;
    if (sessionData) token = (JSON.parse(sessionData) as ISession).token;
    if (request.url.endsWith("/login")) return next.handle(request);
    if (token) {
      if (!request.url.endsWith("logout") || !request.url.endsWith("login"))
        this.userService.keepAliveSession();
      let httpReq = null;
      if (
        request.url.endsWith("logout") === true ||
        request.url.endsWith("mlink") === true ||
        request.url.endsWith("mjoin") === true ||
        request.url.endsWith("link") === true ||
        request.url.endsWith("users") === true ||
        request.url.endsWith("assign") === true ||
        request.url.endsWith("review") === true ||
        request.url.endsWith("purls") === true ||
        request.url.endsWith("MyWork") === true
      ) {
        httpReq = request.clone({
          url: `${request.url}?api_key=${token}`,
        });
      } else {
        httpReq = request.clone({
          url: `${request.url}&api_key=${token}`,
        });
      }
      return next.handle(httpReq);
    }
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403) {
            this.router.navigate(["/login"], {
              queryParams: { reason: "session-expired" },
            });
          }
        }
        return throwError(err);
      })
    );
  }
}
