import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ISessionModal, RESPONSE } from "../types";

@Component({
  selector: "app-alert-modal",
  templateUrl: "./alert-modal.component.html",
  styleUrls: ["./alert-modal.component.scss"],
})
export class AlertModalComponent {
  responses: any;
  isMoreAnalysisRequired: boolean;
  constructor(
    public dialogRef: MatDialogRef<AlertModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISessionModal
  ) {
    this.isMoreAnalysisRequired = false;
    this.responses = { ok: RESPONSE.OK, cancel: RESPONSE.CANCEL };
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
