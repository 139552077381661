import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ISession, IUserLogin, RESPONSE } from "./types";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { MatDialog } from "@angular/material/dialog";
import { PerformanceServiceService } from "./performance-service.service";
import { AlertModalComponent } from "./alert-modal/alert-modal.component";

const BASE_URL = environment.BASE_URL;

@Injectable({
  providedIn: "root",
})
export class UserServiceService {
  private idleState = "Not started.";
  private timedOut = false;
  private keepAlive: boolean;
  constructor(
    private http: HttpClient,
    private router: Router,
    private idle: Idle,
    private keepalive: Keepalive,
    public dialog: MatDialog,
    private performanceService: PerformanceServiceService
  ) {
    this.idle.setIdle(1680);
    this.idle.setTimeout(120);
    this.keepAlive = false;
    this.idle.onTimeout.subscribe(async () => {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("session");
      this.keepAlive = false;
      this.dialog.closeAll();
      this.router.navigate(["/login"], {
        queryParams: { reason: "session-expired" },
      });
    });

    this.idle.onIdleStart.subscribe(
      () => (this.idleState = "You've gone idle!")
    );

    this.idle.onTimeoutWarning.subscribe(async () => {
      if (this.keepAlive === false) {
        this.keepAlive = true;
        const response = await this.openDialog();
        if (response.action === RESPONSE.OK) {
          await performanceService.getUsers().toPromise();
          this.keepAlive = false;
        }
      }
    });
  }
  public login(userData: IUserLogin): Observable<any> {
    return this.http
      .post(`${BASE_URL}/curation_api/login`, JSON.stringify(userData), {
        headers: {
          "Content-Type": "application/json",
          Accept: " application/json",
        },
      })
      .pipe();
  }
  public logOut(): Observable<any> {
    return this.http
      .post(`${BASE_URL}/curation_api/logout`, {
        headers: {
          "Content-Type": "application/json",
          Accept: " application/json",
        },
      })
      .pipe();
  }
  public endSession() {
    this.keepAlive = false;
    this.idleState = "Timed out!";
    this.timedOut = true;
    this.idle.stop();
    sessionStorage.clear();
  }
  public keepAliveSession() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }
  public initSession() {
    this.idle.watch();
  }
  public beforeUnload() {
    const sessionData = sessionStorage.getItem("session");
    let token = null;
    if (sessionData) token = (JSON.parse(sessionData) as ISession).token;
    navigator.sendBeacon(`${BASE_URL}/curation_api/logout?api_key=${token}`);
    this.idleState = "Timed out!";
    this.timedOut = true;
    this.idle.stop();
  }
  private async openDialog() {
    const dialogRef = this.dialog.open(AlertModalComponent, {
      panelClass: "custom-dialog",
      width: "450px",
      height: "230px",
      role: "dialog",
      position: { top: "200px" },
      data: {
        header: `The work will be lost`,
        message: `Do you want to renew your session?`,
        title: "Session will expire in two minutes",
      },
    });
    const response = await dialogRef.afterClosed().toPromise();
    return response;
  }
}
