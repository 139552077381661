<main fxLayout="column" fxFlex="100" >
    <section fxLayout="column" fxLayoutAlign="center left" class="modal-title">
        <p>{{data.title}}</p>
    </section>
    <section class="message">
        <div class="alert">
            <span><mat-icon class="alert-icon">warning</mat-icon></span><p>{{data.header}}</p>
        </div>
        <p>{{data.message}}</p>
    </section>
    <section fxLayout="row" fxLayoutAlign="end"  fxLayoutGap="8px" class="confirm-section">
        <button mat-raised-button [mat-dialog-close]="{action:responses.cancel}">Cancel</button>
        <button mat-raised-button class="confirm"  [mat-dialog-close]="{
    action:responses.ok
    }
    " cdkFocusInitial>Accept</button>
    </section>
</main>
